<template>
    <v-dialog
        ref="dialog"
        width="290px"
    >
        <!--        v-model="date_dialog"-->
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="dateText"
                :disabled="disabled"
                :error-messages="errorMessages"
                :label="label"
                outlined
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                @blur="blur"
                v-on="on"
            ></v-text-field>
        </template>

        <!--
        scrollable
                    :locale="$i18n.locale"
                    color="secondary"
                    header-color="secondary"
                    :picker-date.sync="Array.isArray(date) ? date[0] : date"
                    :show-adjacent-months="true"-->
        <v-date-picker
            v-model="date"
            :locale="$i18n.locale"
            :picker-date.sync="Array.isArray(date) ? undefined : date"
            :range="range"
            :show-adjacent-months="true"
            color="secondary"
            header-color="secondary"
            scrollable
        >
            <v-spacer></v-spacer>
            <!--            <v-btn-->
            <!--                text-->
            <!--                color="secondary"-->
            <!--                @click="date_dialog = false"-->
            <!--            >-->
            <!--                {{ $t("date.cancel") }}-->
            <!--            </v-btn>-->
            <v-btn
                color="error"
                text
                @click="date = null; $refs.dialog.save(null)"
            >
                {{ $t("date.reset") }}
            </v-btn>
            <v-btn
                color="secondary"
                text
                @click="$refs.dialog.save(date);"
            >
                {{ $t("date.ok") }}
            </v-btn>
        </v-date-picker>
    </v-dialog>
</template>

<script>
// https://www.digitalocean.com/community/tutorials/how-to-add-v-model-support-to-custom-vue-js-components
export default {
    name: "DatePicker",
    props: ["label", "value", "errorMessages", "disabled", "range"],
    data() {
        return {
            date: this.value
        };
    },
    watch: {
        value(val) {
            this.date = val;
        },
        date(val) {
            this.$emit("input", val);
        }
    },
    methods: {
        blur() {
            this.$emit("blur");
        }
    },
    computed: {
        dateText() {
            if (Array.isArray(this.date)) {
                return this.date.join(" - ");
            }
            return this.date;
        }
    }
};
</script>

<style scoped>

</style>